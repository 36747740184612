var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Panel',{staticClass:"fill-height",attrs:{"title":"My Reports","loading":_vm.loading,"skeleton-loader":"list-item@12","no-data":!_vm.results.length,"no-data-text":"No reports available"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"border-on-focus",attrs:{"color":"primary","to":"ReportBuilder"}},[_vm._v(" New Report ")])]},proxy:true}])},[_c('div',[_c('v-data-table',{ref:"table",attrs:{"headers":_vm.headers,"items":_vm.results,"sort-by":["lastGeneratedOn"],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ReportBuilder', params: { id: item.id }}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.lastGeneratedOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.lastGeneratedOn))+" ")]}},{key:"item.updatesAvailable",fn:function(ref){
var item = ref.item;
return [(item.updatesAvailable)?_c('v-icon',{attrs:{"small":""}},[_vm._v("check")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-btn',{attrs:{"small":"","icon":"","title":"Delete"},on:{"click":function($event){return _vm.onDeleteQuery(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("$trash")])],1),_c('v-btn',{attrs:{"small":"","icon":"","title":"Generate Report"},on:{"click":function($event){return _vm.generateDocument(item)}}},[_c('v-icon',[_vm._v("download")])],1)],1)]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }